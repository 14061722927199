import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import MHEs from 'wms/MHEs/index';
import Infra from 'wms/MHEs/infra';

export default function MHEsContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div class="bg-background h-full">
          <Router>
            <MHEs path="/wms/MHEs" />
            <Infra path="/wms/MHEs/infra" />
          </Router>
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
